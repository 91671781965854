import { useQuery } from "@apollo/client";
var ORGANIZATION_THEME_QUERY = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "OrganizationThemeQuery"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "slug"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      },
      directives: []
    }],
    directives: [],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "organizationInfo"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "slug"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "slug"
            }
          }
        }],
        directives: [],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "logoHeader"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "logo"
            },
            arguments: [],
            directives: []
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "configuration"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "darkModeLogo"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "darkModeIsotype"
                },
                arguments: [],
                directives: []
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "isotype"
                },
                arguments: [],
                directives: []
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "lookAndFeel"
            },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "primary"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "main"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "secondary"
                },
                arguments: [],
                directives: [],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "main"
                    },
                    arguments: [],
                    directives: []
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  loc: {
    start: 0,
    end: 300,
    source: {
      body: "query OrganizationThemeQuery($slug: String) {\n  organizationInfo(slug: $slug) {\n    name\n    logoHeader\n    logo\n    configuration {\n      darkModeLogo\n      darkModeIsotype\n      isotype\n    }\n    lookAndFeel {\n      primary {\n        main\n      }\n      secondary {\n        main\n      }\n    }\n  }\n}\n",
      name: "GraphQL request",
      locationOffset: {
        line: 1,
        column: 1
      }
    }
  }
};
/**
 * @description Hook to get the organization theme necessary to render custom organization logo.
 * @todo This hook should be moved to outside the web package and be used by every page.
 */
var useOrganizationTheme = function useOrganizationTheme(organizationSlug, args) {
  var skip = (args === null || args === void 0 ? void 0 : args.skip) || !organizationSlug;
  var _useQuery = useQuery(ORGANIZATION_THEME_QUERY, {
      context: {
        clientName: 'b2b'
      },
      variables: {
        slug: organizationSlug
      },
      skip: skip
    }),
    data = _useQuery.data,
    loading = _useQuery.loading,
    error = _useQuery.error;
  return {
    data: data === null || data === void 0 ? void 0 : data.organizationInfo,
    loading: loading,
    error: error
  };
};
export default useOrganizationTheme;