import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useMemo } from "react";
import { DEFAULT_MAIN_MENU_NAV_THEMES, DEFAULT_MAIN_MENU_SEARCH_DIALOG_THEMES, DEFAULT_MAIN_MENU_SEARCH_INPUT_THEMES, DEFAULT_MAIN_MENU_SUBMENU_THEMES } from "../../themes";
var getMainMenuThemes = function getMainMenuThemes(_ref) {
  var _customThemes$light, _customThemes$light2, _customThemes$light2$, _customThemes$dark, _customThemes$dark2, _customThemes$dark2$n, _customThemes$light3, _customThemes$dark3, _customThemes$light4, _customThemes$light5, _customThemes$light6, _customThemes$dark4, _customThemes$dark5, _customThemes$dark6;
  var customThemes = _ref.customThemes;
  var mergedNavThemes = {
    light: _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_NAV_THEMES.light), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$light = customThemes.light) === null || _customThemes$light === void 0 ? void 0 : _customThemes$light.nav), {}, {
      logo: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_NAV_THEMES.light.logo), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$light2 = customThemes.light) === null || _customThemes$light2 === void 0 ? void 0 : (_customThemes$light2$ = _customThemes$light2.nav) === null || _customThemes$light2$ === void 0 ? void 0 : _customThemes$light2$.logo)
    }),
    dark: _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_NAV_THEMES.dark), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$dark = customThemes.dark) === null || _customThemes$dark === void 0 ? void 0 : _customThemes$dark.nav), {}, {
      logo: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_NAV_THEMES.dark.logo), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$dark2 = customThemes.dark) === null || _customThemes$dark2 === void 0 ? void 0 : (_customThemes$dark2$n = _customThemes$dark2.nav) === null || _customThemes$dark2$n === void 0 ? void 0 : _customThemes$dark2$n.logo)
    })
  };
  var mergedSubmenuThemes = {
    light: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_SUBMENU_THEMES.light), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$light3 = customThemes.light) === null || _customThemes$light3 === void 0 ? void 0 : _customThemes$light3.submenu),
    dark: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_SUBMENU_THEMES.dark), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$dark3 = customThemes.dark) === null || _customThemes$dark3 === void 0 ? void 0 : _customThemes$dark3.submenu)
  };
  return {
    light: {
      nav: mergedNavThemes.light,
      submenu: mergedSubmenuThemes.light,
      searchInput: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_SEARCH_INPUT_THEMES.light), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$light4 = customThemes.light) === null || _customThemes$light4 === void 0 ? void 0 : _customThemes$light4.searchInput),
      searchDialog: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_SEARCH_DIALOG_THEMES.light), {}, {
        backgroundColorClassName: mergedSubmenuThemes.light.backgroundColorClassName,
        textColorClassName: mergedSubmenuThemes.light.textColorClassName,
        dividerColorClassName: mergedSubmenuThemes.light.dividerColorClassName
      }, customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$light5 = customThemes.light) === null || _customThemes$light5 === void 0 ? void 0 : _customThemes$light5.searchDialog),
      searchOverlay: _objectSpread({
        backgroundColorClassName: mergedNavThemes.light.backgroundColorClassName,
        textColorClassName: mergedNavThemes.light.textColorClassName,
        dividerColorClassName: mergedNavThemes.light.dividerColorClassName
      }, customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$light6 = customThemes.light) === null || _customThemes$light6 === void 0 ? void 0 : _customThemes$light6.searchOverlay)
    },
    dark: {
      nav: mergedNavThemes.dark,
      submenu: mergedSubmenuThemes.dark,
      searchInput: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_SEARCH_INPUT_THEMES.dark), customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$dark4 = customThemes.dark) === null || _customThemes$dark4 === void 0 ? void 0 : _customThemes$dark4.searchInput),
      searchDialog: _objectSpread(_objectSpread({}, DEFAULT_MAIN_MENU_SEARCH_DIALOG_THEMES.dark), {}, {
        backgroundColorClassName: mergedSubmenuThemes.dark.backgroundColorClassName,
        textColorClassName: mergedSubmenuThemes.dark.textColorClassName,
        dividerColorClassName: mergedSubmenuThemes.dark.dividerColorClassName
      }, customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$dark5 = customThemes.dark) === null || _customThemes$dark5 === void 0 ? void 0 : _customThemes$dark5.searchDialog),
      searchOverlay: _objectSpread({
        backgroundColorClassName: mergedNavThemes.dark.backgroundColorClassName,
        textColorClassName: mergedNavThemes.dark.textColorClassName,
        dividerColorClassName: mergedNavThemes.dark.dividerColorClassName
      }, customThemes === null || customThemes === void 0 ? void 0 : (_customThemes$dark6 = customThemes.dark) === null || _customThemes$dark6 === void 0 ? void 0 : _customThemes$dark6.searchOverlay)
    }
  };
};
var useMainMenuTheme = function useMainMenuTheme(_ref2) {
  var customThemes = _ref2.customThemes,
    _ref2$theme = _ref2.theme,
    theme = _ref2$theme === void 0 ? 'light' : _ref2$theme,
    isTransparent = _ref2.isTransparent,
    searchOverlayIsOpen = _ref2.searchOverlayIsOpen;
  var themes = useMemo(function () {
    return getMainMenuThemes({
      customThemes: customThemes
    });
  }, [customThemes]);
  if (typeof theme === 'string') {
    return {
      theme: themes[theme],
      themeName: {
        nav: theme,
        submenu: theme,
        searchInput: theme,
        searchDialog: theme,
        searchOverlay: theme
      }
    };
  }
  var searchOverlayThemeName = theme.searchOverlay || (isTransparent ? theme.submenu : theme.nav);
  var navThemeName = searchOverlayIsOpen ? searchOverlayThemeName : theme.nav;
  var themeName = {
    nav: navThemeName,
    submenu: theme.submenu,
    searchInput: theme.searchInput || navThemeName,
    searchDialog: theme.searchDialog || theme.submenu,
    searchOverlay: searchOverlayThemeName
  };
  return {
    theme: {
      nav: themes[navThemeName].nav,
      submenu: themes[themeName.submenu].submenu,
      searchInput: themes[themeName.searchInput].searchInput,
      searchDialog: themes[themeName.searchDialog].searchDialog,
      searchOverlay: themes[themeName.searchOverlay].searchOverlay
    },
    themeName: themeName
  };
};
export default useMainMenuTheme;