import parseQueryStrings from "./parseQueryStrings";
export function getNextUrl() {
  if (typeof window === 'undefined') {
    return '';
  }
  if (window.nextUrl) {
    return window.nextUrl;
  }
  var _ref = parseQueryStrings(window.location.search) || {},
    nextUrl = _ref.next;
  return typeof nextUrl === 'string' ? nextUrl : '';
}
export default getNextUrl;