import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { screens } from "@crehana/ui";
function useMediaQuery(breakpointKey) {
  var mediaQuery = "(min-width: ".concat(screens[breakpointKey], ")");
  var nativeMediaQueryList = typeof window !== 'undefined' ? window.matchMedia(mediaQuery) : undefined;
  var _useState = useState(nativeMediaQueryList === null || nativeMediaQueryList === void 0 ? void 0 : nativeMediaQueryList.matches),
    _useState2 = _slicedToArray(_useState, 2),
    matches = _useState2[0],
    setMatches = _useState2[1];
  useEffect(function () {
    if (nativeMediaQueryList) {
      var listener = function listener(event) {
        setMatches(event.matches);
      };
      if (typeof nativeMediaQueryList.addEventListener === 'function') {
        nativeMediaQueryList.addEventListener('change', listener);
      } else if (typeof nativeMediaQueryList.addListener === 'function') {
        nativeMediaQueryList.addListener(listener);
      }
      return function () {
        if (typeof nativeMediaQueryList.removeEventListener === 'function') {
          nativeMediaQueryList.removeEventListener('change', listener);
        } else if (typeof nativeMediaQueryList.removeListener === 'function') {
          nativeMediaQueryList.removeListener(listener);
        }
      };
    }
  }, [nativeMediaQueryList]);
  return {
    matches: matches,
    nativeMediaQueryList: nativeMediaQueryList
  };
}
export default useMediaQuery;