import { useMemo } from "react";
import useMainMenuContext from "./useMainMenuContext";
var useScreenLgBreakpoint = function useScreenLgBreakpoint() {
  var isMainMenuLight = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _useMainMenuContext = useMainMenuContext(),
    embeddedIn = _useMainMenuContext.embeddedIn,
    currentBreakpointKey = _useMainMenuContext.currentBreakpointKey,
    layoutConfig = _useMainMenuContext.layoutConfig;
  var isEmbeddedInNewDashboard = embeddedIn === 'new-dashboard';
  var isLg = useMemo(function () {
    if (isEmbeddedInNewDashboard) {
      var defaultEmbeddedInBreakpoints = ['md', 'lg', 'xl'];
      var embeddedInBreakpoints = layoutConfig.embeddedInBreakpoints || defaultEmbeddedInBreakpoints;
      return embeddedInBreakpoints.includes(currentBreakpointKey);
    }
    return isMainMenuLight ? currentBreakpointKey === 'md' : currentBreakpointKey === 'lg';
  }, [currentBreakpointKey, isEmbeddedInNewDashboard, isMainMenuLight]);
  return {
    isLg: isLg,
    isEmbeddedInNewDashboard: isEmbeddedInNewDashboard
  };
};
export default useScreenLgBreakpoint;