export var MAIN_MENU_TRANSLATION_KEY = 'main-menu';
export var MAIN_MENU_AMPLITUDE_SOURCE_DETAIL_KEY = 'Main Menu';
export var SEARCH_DIALOG_AMPLITUDE_SOURCE_DETAIL_KEY = 'Search Dialog';
export var COOKIES_CONSENT_KEY = 'SHOW_COOKIES_NOTIFICATION';
export var NOTIFICATION_AUDIO_URL = 'https://static.crehana.com/static/files/Notification.mp3';
export var PHONE_LEAD_CARD_IMG = 'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/10/18/54ae473e/1b43a7fb.png?auto=format';
export var USER_MENU_AVATAR_TOUR_ID = 'main-menu-user-menu-avatar';
export var DEFAULT_MAIN_MENU_URLS = {
  login: '/entrar/',
  register: '/onboarding/',
  homePage: '/',
  homeUser: '/home/',
  // Dashboards and user settings
  userDashboard: {
    courses: '/panel/mis-cursos/',
    savedCourses: '/panel/mis-cursos-guardados/',
    microDegree: '/panel/mis-cursos/microdegree/',
    projects: '/panel/mis-proyectos/',
    diplomas: '/panel/mis-diplomas/'
  },
  mentorDashboard: '/mentor/panel/proyectos/',
  teacherDashboard: '/panel-profesor/',
  affiliateDashboard: '/afiliados/panel/',
  shoppingCart: '/carrito-de-compras/',
  configuration: '/configuracion/',
  // Landings
  premiumLanding: '/premium/',
  crehanaAcademiesLanding: '/academias/',
  premiumRefer: '/referidos/',
  // B2BLanding: '/planes/',
  higherEdLanding: '/business/higher-education/',
  // Catalog and resources
  searchLanding: '/cursos-online/search/',
  catalog: '/cursos-online/?all=true',
  newCoursesCatalog: '/cursos-nuevos/',
  blog: '/blog/',
  crehanaLive: '/live/',
  resources: '/recursos/herramientas/',
  courseAgenda: '/agenda-cursos/',
  mentorLanding: '/mentores/',
  inbox: '/inbox/',
  // Help links
  helpCenter: 'https://ayuda.crehana.com/',
  legal: '/legal/terminos-y-condiciones/',
  // Apps
  appDownload: {
    apple: 'https://itunes.apple.com/us/app/crehana/id1353031284',
    google: 'https://play.google.com/store/apps/details?id=com.crehana.android',
    huawei: 'https://appgallery.cloud.huawei.com/ag/n/app/C101001725'
  },
  // Utility functions
  createHomeUserB2BUrl: function createHomeUserB2BUrl(organizationSlug) {
    return "/org/".concat(organizationSlug, "/learn/");
  },
  createB2BAdminDashboardUrl: function createB2BAdminDashboardUrl(organizationSlug) {
    return "/org/".concat(organizationSlug, "/panel/");
  },
  createCatalogKnowledgeHubUrl: function createCatalogKnowledgeHubUrl(organizationSlug) {
    return "/org/".concat(organizationSlug, "/learn/content/");
  },
  renewalLanding: '/renovacion/',
  B2BPlansLanding: '/planes',
  downloadableResources: '/business/descargables/'
};
export var SEARCH_KEYWORDS_COUNT = 10;
export var SEARCH_RESULTS_COUNT = {
  xs: 10,
  sm: 14,
  md: 12,
  lg: 18
};
export var SEARCH_DEFAULT_COURSE_SECTION_COUNT = {
  xs: 6,
  sm: 6,
  md: 4,
  lg: 6
};
export var SEARCH_CATEGORIES_RECOMMENDED_COUNT = 3;
export var SEARCH_BEST_SELLER_COURSES_PHONE_LEAD_CARD_POSITION = 2;
export var SEARCH_RESULTS_PHONE_LEAD_CARD_POSITION = 1;
export var NEW_COURSES_ORDER_BY = 'nuevos';
export var NOTIFICATIONS_COURSES_PER_FETCH = 6;
export var NOTIFICATIONS_NEWS_PER_FETCH = 8;
export var USER_MENU_RENEWAL_ITEM_SOURCE_DETAIL = 'Main Menu Renewal Card';

// CatalogMenu
export var CATALOG_NEW_COURSES_POPPER_COUNT = 3;