import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { matchMediaQuery } from "@crehana/utils";
var getVisibleHeight = function getVisibleHeight(_ref) {
  var elementHeight = _ref.elementHeight,
    scrolledValue = _ref.scrolledValue;
  return scrolledValue > elementHeight ? 0 : elementHeight - scrolledValue;
};
var useVisibleHeight = function useVisibleHeight(_ref2) {
  var position = _ref2.position,
    theme = _ref2.theme,
    topBannerIsOpen = _ref2.topBannerIsOpen;
  var navHeight = theme.nav.height;
  var _useState = useState(0),
    _useState2 = _slicedToArray(_useState, 2),
    scrollTop = _useState2[0],
    setScrollTop = _useState2[1];
  var _useState3 = useState(0),
    _useState4 = _slicedToArray(_useState3, 2),
    topBannerHeight = _useState4[0],
    setTopBannerHeight = _useState4[1];
  var _useState5 = useState({
      mainMenu: {
        xs: navHeight.xs,
        sm: navHeight.sm,
        md: navHeight.md,
        lg: navHeight.lg
      },
      topBanner: 0
    }),
    _useState6 = _slicedToArray(_useState5, 2),
    visibleHeight = _useState6[0],
    setVisibleHeight = _useState6[1];
  useEffect(function () {
    var onScroll = _debounce(function () {
      setScrollTop(document.documentElement.scrollTop);
    }, 100);
    window.addEventListener('scroll', onScroll);
    return function () {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  var calculateTopBannerHeight = function calculateTopBannerHeight() {
    var topBannerElement = document.getElementById('mainMenuTopBanner');
    setTopBannerHeight(topBannerElement ? topBannerElement.clientHeight : 0);
  };
  useEffect(function () {
    return calculateTopBannerHeight();
  }, [topBannerIsOpen]);
  useEffect(function () {
    var nativeMediaQueryList = matchMediaQuery('sm');
    if (typeof nativeMediaQueryList.addEventListener === 'function') {
      nativeMediaQueryList.addEventListener('change', calculateTopBannerHeight);
    } else if (typeof nativeMediaQueryList.addListener === 'function') {
      nativeMediaQueryList.addListener(calculateTopBannerHeight);
    }
    return function () {
      if (typeof nativeMediaQueryList.removeEventListener === 'function') {
        nativeMediaQueryList.removeEventListener('change', calculateTopBannerHeight);
      } else if (typeof nativeMediaQueryList.removeListener === 'function') {
        nativeMediaQueryList.removeListener(calculateTopBannerHeight);
      }
    };
  }, []);
  useEffect(function () {
    var scrolledValue = document.documentElement.scrollTop;
    var visibleNavHeight = position === 'sticky' || !scrollTop ? navHeight : {
      xs: getVisibleHeight({
        elementHeight: navHeight.xs,
        scrolledValue: scrolledValue
      }),
      sm: getVisibleHeight({
        elementHeight: navHeight.sm,
        scrolledValue: scrolledValue
      }),
      md: getVisibleHeight({
        elementHeight: navHeight.md,
        scrolledValue: scrolledValue
      }),
      lg: getVisibleHeight({
        elementHeight: navHeight.lg,
        scrolledValue: scrolledValue
      })
    };
    var topBannerVisibleHeight = topBannerHeight ? getVisibleHeight({
      elementHeight: topBannerHeight,
      scrolledValue: scrolledValue
    }) : 0;
    setVisibleHeight({
      mainMenu: {
        xs: visibleNavHeight.xs + topBannerVisibleHeight,
        sm: visibleNavHeight.sm + topBannerVisibleHeight,
        md: visibleNavHeight.md + topBannerVisibleHeight,
        lg: visibleNavHeight.lg + topBannerVisibleHeight
      },
      topBanner: topBannerVisibleHeight
    });
  }, [scrollTop, position, navHeight, topBannerHeight]);
  return {
    mainMenuVisibleHeight: visibleHeight.mainMenu,
    topBannerVisibleHeight: visibleHeight.topBanner
  };
};
export default useVisibleHeight;