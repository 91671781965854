import { createUrlWithSourceQueryParams } from "@crehana/analytics";
import { MAIN_MENU_AMPLITUDE_SOURCE_DETAIL_KEY } from "../constants";
var createUrlWithAmplitudeSource = function createUrlWithAmplitudeSource(_ref) {
  var url = _ref.url,
    amplitudePageName = _ref.amplitudePageName,
    sourceDetail = _ref.sourceDetail;
  return createUrlWithSourceQueryParams({
    url: url,
    source: amplitudePageName,
    sourceDetail: sourceDetail || MAIN_MENU_AMPLITUDE_SOURCE_DETAIL_KEY
  });
};
export default createUrlWithAmplitudeSource;