import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useRef, useState } from "react";
var useSearchTerm = function useSearchTerm(_ref) {
  var _ref$initialSearchTer = _ref.initialSearchTerm,
    initialSearchTerm = _ref$initialSearchTer === void 0 ? '' : _ref$initialSearchTer;
  var _useState = useState({
      value: initialSearchTerm,
      cleanValue: initialSearchTerm,
      evaluatedValue: initialSearchTerm,
      isValid: initialSearchTerm.trim().length > 1
    }),
    _useState2 = _slicedToArray(_useState, 2),
    searchTerm = _useState2[0],
    setSearchTerm = _useState2[1];
  var textFieldRef = useRef(null);
  var focusTextField = function focusTextField() {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };
  var blurTextField = function blurTextField() {
    if (textFieldRef.current) {
      textFieldRef.current.blur();
    }
  };
  var onSearchTermChange = function onSearchTermChange(value) {
    var cleanValue = value.trim();
    setSearchTerm(function (prevState) {
      return _objectSpread(_objectSpread({}, prevState), {}, {
        value: value,
        cleanValue: cleanValue,
        isValid: cleanValue.length > 1
      });
    });
  };
  var onEvaluatedSearchTermChange = function onEvaluatedSearchTermChange(evaluatedValue) {
    setSearchTerm(function (prevState) {
      return _objectSpread(_objectSpread({}, prevState), {}, {
        evaluatedValue: evaluatedValue
      });
    });
  };
  return {
    searchTerm: searchTerm,
    onSearchTermChange: onSearchTermChange,
    onEvaluatedSearchTermChange: onEvaluatedSearchTermChange,
    searchTextField: {
      ref: textFieldRef,
      focus: focusTextField,
      blur: blurTextField
    }
  };
};
export default useSearchTerm;