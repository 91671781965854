import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useMe } from "@crehana/auth";
import { MembershipTypeMethod, UserDegreeSegmentationEnum } from "../../../__generated__/graphql-schema";
function useUserWithRoleAndPermissions() {
  var _membership$membershi;
  var _useMe = useMe(),
    me = _useMe.me,
    loading = _useMe.loading;
  var _ref = me || {},
    isMentor = _ref.isMentor,
    isProfessor = _ref.isProfessor,
    isAffiliate = _ref.isAffiliate,
    isPremium = _ref.isPremium,
    membership = _ref.membership,
    hasOrganization = _ref.hasOrganization,
    organization = _ref.organization,
    userDegreeSegmentation = _ref.userDegreeSegmentation;
  var isAnonymous = !loading && !me;
  var isTeacher = !!isProfessor;
  var isDegreeStudent = userDegreeSegmentation === UserDegreeSegmentationEnum.ACTIVATED_DEGREE_SEGMENTATION;
  var _ref2 = organization || {},
    organizationSlug = _ref2.slug,
    organizationRole = _ref2.myRole;
  var isB2B = !!hasOrganization && !!organizationSlug;
  var isB2BAdmin = organizationRole === 'admin' || organizationRole === 'owner' || organizationRole === 'owner-corporation';
  var isPAYG = !isAnonymous && !isMentor && !isTeacher && !isPremium && !isDegreeStudent && !isB2B && !isB2BAdmin;
  var hasInboxAccess = isMentor || isB2B || isTeacher || !!isPremium &&
  // Not available for free trial membership or brazil users
  (membership === null || membership === void 0 ? void 0 : (_membership$membershi = membership.membershipType) === null || _membership$membershi === void 0 ? void 0 : _membership$membershi.method) !== MembershipTypeMethod.A_2;
  return {
    me: _objectSpread(_objectSpread({}, me), {}, {
      isAnonymous: isAnonymous,
      isMentor: !!isMentor,
      isTeacher: isTeacher,
      isAffiliate: !!isAffiliate,
      isPremium: !!isPremium,
      isDegreeStudent: isDegreeStudent,
      isB2B: isB2B,
      isB2BAdmin: isB2BAdmin,
      isPAYG: isPAYG,
      hasInboxAccess: hasInboxAccess
    }),
    loading: loading
  };
}
export default useUserWithRoleAndPermissions;