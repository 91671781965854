import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { useAmplitude } from "@crehana/analytics";
import { KEY_NAMES } from "@crehana/ui";
var useActiveSubmenu = function useActiveSubmenu(_ref) {
  var amplitudePageName = _ref.amplitudePageName,
    onLeftMenuClick = _ref.onLeftMenuClick,
    onChangeActiveSubmenu = _ref.onChangeActiveSubmenu;
  var _useAmplitude = useAmplitude(),
    logEvent = _useAmplitude.logEvent;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    activeSubMenu = _useState2[0],
    setActiveSubMenu = _useState2[1];
  useEffect(function () {
    if (onChangeActiveSubmenu) {
      onChangeActiveSubmenu(activeSubMenu);
    }
  }, [activeSubMenu, onChangeActiveSubmenu]);
  useEffect(function () {
    function handleKeyDown(e) {
      if (e.key === KEY_NAMES.ESCAPE) {
        // The escape key functionality is handled by the Search component itself
        setActiveSubMenu(function (prevActiveSubmenu) {
          return prevActiveSubmenu === 'search' ? prevActiveSubmenu : undefined;
        });
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return function () {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  var onLeftMenuOpen = function onLeftMenuOpen() {
    if (typeof onLeftMenuClick === 'function') {
      onLeftMenuClick();
    } else {
      setActiveSubMenu('left-menu-drawer');
    }
  };
  var onCatalogMenuOpen = function onCatalogMenuOpen() {
    setActiveSubMenu('catalog-menu');
  };
  var onResourcesMenuOpen = function onResourcesMenuOpen() {
    setActiveSubMenu('resources-menu');
  };
  var onSearchMenuOpen = function onSearchMenuOpen() {
    setActiveSubMenu('search');
    logEvent({
      event: 'Search Opened',
      data: {
        Source: amplitudePageName
      }
    });
  };
  var onShoppingCartOpen = function onShoppingCartOpen() {
    setActiveSubMenu('shopping-cart-popper');
  };
  var onNotificationsOpen = function onNotificationsOpen() {
    setActiveSubMenu('notifications-drawer');
  };
  var onUserMenuOpen = function onUserMenuOpen() {
    setActiveSubMenu('user-menu');
  };
  var onCloseSubmenu = function onCloseSubmenu() {
    setActiveSubMenu(undefined);
  };
  var onToggleSubmenu = function onToggleSubmenu(submenu) {
    if (activeSubMenu === submenu) {
      onCloseSubmenu();
    } else {
      setActiveSubMenu(submenu);
    }
  };
  return {
    activeSubMenu: activeSubMenu,
    onLeftMenuOpen: onLeftMenuOpen,
    onCatalogMenuOpen: onCatalogMenuOpen,
    onResourcesMenuOpen: onResourcesMenuOpen,
    onSearchMenuOpen: onSearchMenuOpen,
    onShoppingCartOpen: onShoppingCartOpen,
    onNotificationsOpen: onNotificationsOpen,
    onUserMenuOpen: onUserMenuOpen,
    onCloseSubmenu: onCloseSubmenu,
    onToggleSubmenu: onToggleSubmenu
  };
};
export default useActiveSubmenu;